import { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import Flex from '/imports/core/ui/atoms/Flex';
import { destroyCookie, setCookie, parseCookies } from 'nookies';
import { fetchAgentPolishResume, fetchMarkAsPolished } from 'imports/job-tracking/api/api';
import env from '/env';
import { inputStyle } from 'imports/core/ui/mixins';
import { useSearchParams } from 'next/navigation';
import moment from 'moment';
import { debounce } from 'lodash';
import DropdownAutocomplete from 'imports/core/ui/atoms/new-ui/DropdownAutoComplete';

const catOptions = [
  {
    title: 'Pre Quiz Search Template',
    value: 'pre-quizz',
  },
  {
    title: 'Post Quiz Search Template and all',
    value: 'post-quizz',
  },
];

const PolishedResumes = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [Disable, setDisable] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [nameOrEmail, setNameOrEmail] = useState('');
  const [cat, setCat] = useState('');
  const [pagination, setPagination] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const renderRef = useRef(0);
  const dataRef = useRef([]);
  const params = useSearchParams();
  const { agent_client_id } = parseCookies();

  const fetchPreResume = async (isFirstTime = false, resetData = false) => {
    if (!isFirstTime && pagination?.totalPages === pagination?.page) return;
    setLoading(true);
    setError(null);
    try {
      const q = isFirstTime && !resetData ? params.get('query_filter') : nameOrEmail;
      let resp;
      if (q) {
        const encodedValue = encodeURIComponent(q);
        isFirstTime && !resetData && setNameOrEmail(q);
        resp = await fetchAgentPolishResume(currentPage, encodedValue);
      } else {
        resp = await fetchAgentPolishResume(currentPage);
      }
      if (resp?.pagination.totalPages !== currentPage) {
        setCurrentPage(currentPage + 1);
      }
      setPagination(resp.pagination);
      const sortedData = (resp.data || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      dataRef.current = resetData ? sortedData : [...dataRef.current, ...sortedData];
      if (cat) {
        updateDataByCat(cat);
      } else {
        setData(dataRef.current);
      }
    } catch (err) {
      console.error('Error fetching resumes:', err);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleMarkAsPolished = async (builderUserId, isPreQuiz, item) => {
    const resumeId = isPreQuiz ? item.resumeDetails.preQuizResumeId : item.resumeDetails.resumeId;
    try {
      const payload = {
        builderUserId,
        isPreQuizResume: isPreQuiz,
      };
      const resp1 = await fetchMarkAsPolished(payload);
      if (resp1.isPolished) {
        setDisable((prev) => ({ ...prev, [builderUserId]: true }));
        setDataLoading((prev) => ({ ...prev, [builderUserId]: true }));
      }
      alert(`Resume ${builderUserId} marked as polished!`);
    } catch (error) {
      console.error('Error marking as polished:', error);
      alert('Failed to mark as polished. Please try again.');
    } finally {
      setDataLoading((prev) => ({ ...prev, [builderUserId]: false }));
    }
  };

  const debouncedFetch = useCallback(
    debounce(async (value) => {
      const encodedValue = encodeURIComponent(value);
      const resp = await fetchAgentPolishResume(1, encodedValue);
      if (resp.data.length > 0) {
        setData(resp.data);
        setCurrentPage(1);
        setPagination(resp.pagination);
      } else {
        fetchPreResume(true, true);
      }
    }, 500),
    [],
  );

  const handleValueChange = async (e) => {
    const value = e.target.value?.trim();
    setNameOrEmail(value);
    debouncedFetch(value);
    updateParamsUrl('query_filter', value);
  };

  const updateParamsUrl = (key, value) => {
    const url = new URL(window?.location?.href);
    if (value) {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }
    window.history.replaceState({}, '', url);
  };

  useEffect(() => {
    fetchPreResume(true);
  }, []);

  const handleRoute = (item, isPreQuiz) => () => {
    if (agent_client_id) {
      document.cookie = 'agent_client_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = 'agent_client_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = 'agent_client_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
    setCookie(null, 'agent_client_id', item.agentClientUserId, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    });
    const resumeId = isPreQuiz
      ? item.resumeDetails.preQuizResumeId
      : !item.resumeDetails?.resumeId && item.resumeDetails.preQuizResumeId
        ? item.resumeDetails.preQuizResumeId
        : item.resumeDetails.resumeId;
    const url = `${window.origin}/resume/${resumeId}/finish?agentClientUserId=${item.agentClientUserId}`;
    window.open(url, '_blank');
    window.location.reload();
  };

  const handleLinkOGResume = (item) => () => {
    if (agent_client_id) {
      document.cookie = 'agent_client_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = 'agent_client_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = 'agent_client_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
    setCookie(null, 'agent_client_id', item.builderUserId, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/',
    });
    const url = `${window.origin}/resume/${item.resumeDetails?.originalResumeId}/preview?agentClientUserId=${item.builderUserId}`;
    window.open(url, '_blank');
    window.location.reload();
  };

  const updateDataByCat = (value) => {
    if (value == 'post-quizz') {
      setData(
        dataRef?.current?.filter((i) => {
          return i.resumeDetails?.resumeId;
        }),
      );
    } else {
      setData(
        dataRef?.current?.filter((i) => {
          return i.resumeDetails?.preQuizResumeId || !i.resumeDetails?.resumeId;
        }),
      );
    }
  };

  const onSelectCat = (item) => {
    updateParamsUrl('cat_filter', item.value);
    setCat(item.value);
    updateDataByCat(item.value);
  };

  return (
    <Container>
      <Title>Pending Resumes to Polish ({pagination?.total})</Title>
      <Flex $justifyContent="end" style={{ width: '100%', gap: 16 }}>
        <FormGroup $direction="column">
          <Label htmlFor="input">Filter by Category:</Label>
          <DropdownAutocomplete options={catOptions} onSelect={onSelectCat} />
        </FormGroup>
        <FormGroup $direction="column">
          <Label htmlFor="input">Filter by Candidate Email:</Label>
          <Input value={nameOrEmail} onChange={handleValueChange} />
        </FormGroup>
      </Flex>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <InfiniteScroll
        dataLength={data.length}
        next={() => fetchPreResume(false)}
        hasMore={currentPage < pagination?.totalPages}
        loader={<LoadingMessage>Loading...</LoadingMessage>}
        endMessage={<NoDataMessage>No more resumes to display.</NoDataMessage>}
      >
        <Table>
          <thead>
            <Tr>
              <Th>Candidate Email</Th>
              <Th>Candidate Name</Th>
              <Th>Role</Th>
              <Th>Lang</Th>
              <Th>Original Resume</Th>
              <Th>Created At</Th>
              <Th>Resume Category</Th>
              <Th>Resume</Th>
              <Th>Actions</Th>
            </Tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              const hasResumeId = item.resumeDetails?.resumeId;
              const hasPreQuizResumeId = item.resumeDetails?.preQuizResumeId;
              const isPostQuizDone = item.resumeDetails?.isPostQuizTemplateDone;
              const isPreQuizDone = item.resumeDetails?.isPreQuizTemplateDone;
              const hasBothResumes = hasResumeId && hasPreQuizResumeId;
              return (
                <>
                  <Tr key={`${index}-main`}>
                    <Td rowSpan={hasBothResumes ? 2 : 1}>{item.candidateData?.candidateEmail}</Td>
                    <Td rowSpan={hasBothResumes ? 2 : 1}>{item.candidateData?.candidateName}</Td>
                    <Td rowSpan={hasBothResumes ? 2 : 1}>{item.role}</Td>
                    <Td rowSpan={hasBothResumes ? 2 : 1}>{item.resumeDetails?.language?.toUpperCase()}</Td>
                    <Td rowSpan={hasBothResumes ? 2 : 1}>
                      {item.resumeDetails?.originalResumeId ? (
                        <StyledDiv onClick={handleLinkOGResume(item)}>Link Resume</StyledDiv>
                      ) : (
                        '-'
                      )}
                    </Td>
                    <Td>{moment(item.createdAt).format('YYYY/MM/DD HH:mm')}</Td>
                    <Td>{hasResumeId ? 'Post Quiz Search Template' : 'Pre Quiz Search Template'}</Td>
                    <Td>
                      <StyledDiv onClick={handleRoute(item, !hasResumeId)}>Link Resume</StyledDiv>
                    </Td>

                    <Td>
                      {hasResumeId && isPostQuizDone ? (
                        <DoneButton>{'Done'}</DoneButton>
                      ) : (
                        <Button
                          disabled={Disable[item.builderUserId]}
                          onClick={() => handleMarkAsPolished(item.builderUserId, !hasResumeId, item)}
                        >
                          {dataLoading[item.builderUserId] ? <Loader /> : 'Mark as Polished'}
                        </Button>
                      )}
                    </Td>
                  </Tr>
                  {hasBothResumes && (
                    <Tr key={`${index}-additional`}>
                      <Td>{moment(item.createdAt).format('YYYY/MM/DD HH:mm')}</Td>
                      <Td>Pre Quiz Search Template</Td>
                      <Td>
                        <StyledDiv onClick={handleRoute(item, true)}>Link Resume</StyledDiv>
                      </Td>

                      <Td>
                        {hasPreQuizResumeId && isPreQuizDone ? (
                          <DoneButton>{'Done'}</DoneButton>
                        ) : (
                          <Button
                            disabled={Disable[item.builderUserId]}
                            onClick={() => handleMarkAsPolished(item.builderUserId, !hasResumeId, item)}
                          >
                            {dataLoading[item.builderUserId] ? <Loader /> : 'Mark as Polished'}
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  )}
                </>
              );
            })}
          </tbody>
        </Table>
      </InfiniteScroll>
      {!loading && !error && data?.length === 0 && <NoDataMessage>No resumes to display.</NoDataMessage>}
    </Container>
  );
};

export default PolishedResumes;

const FormGroup = styled(Flex)``;

const Input = styled.input`
  ${inputStyle}
  font-size: 14px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 3px;
  box-shadow: none;
  min-width: 320px;
  border: solid 2px #e6e6ff;
  background-color: var(--light-values-white);

  ${({ showIcon }) =>
    !showIcon &&
    css`
      padding: 13px 16px 9px;
    `}

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.gray.light};
  }

  &:focus {
    border: solid 2px #1688fe;
  }

  ${(p) =>
    p.light &&
    css`
      background: #fff;
    `}
  ${(p) =>
    p.expanded &&
    p.dark &&
    css`
      z-index: 99;
      border: 1px solid #dbe0e5;
    `}

  ${(p) =>
    p.showIcon &&
    css`
      padding-left: 33px;
    `}

  ${(p) => p.theme.max('xs')`
    background: #fff;
    border-radius: 3px;
    border: solid 2px #e6e6ff;
    padding: 13px 16px 9px 33px;
  `};
`;
const Label = styled.label`
  margin-bottom: 8px;
  display: block;
  font-size: 14px;
  font-family: 'Gilroy Medium';
  color: #01132c;
`;
const StyledDiv = styled.div`
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
`;

const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: 25px;
  height: 100vh;
  width: 100%;
  .infinite-scroll-component__outerdiv,
  .infinite-scroll-component {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: bold;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  margin-top: 20px;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f4f4f4;
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    pointer-events: none;
    background-color: grey;
    width: 129.3px;
    display: flex;
    justify-content: center;
  }
`;

const DoneButton = styled(Button)`
  background-color: green;
  padding: 12px 45px;
  cursor: not-allowed;
`;

const LoadingMessage = styled.p`
  font-size: 1rem;
  color: #666;
`;

const ErrorMessage = styled.p`
  font-size: 1rem;
  color: red;
`;

const NoDataMessage = styled.p`
  font-size: 1rem;
  color: #666;
`;

const Loader = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #007bff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 0.5s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
